
import {Component, Vue} from 'vue-property-decorator'
import BasePanel from '@/components/base/BasePanel.vue'
import { AppointmentResource, FormsMetadataResource, SettingsResource } from '@/resources'
import BaseSchedulingEntry from '@/components/scheduling/BaseSchedulingEntry.vue'
import { BaseSettingComponent } from '@/components/base/BaseMixins'
import UserSelector from '@/components/user/UserSelector.vue'

@Component({metaInfo: {title:process.env.VUE_APP_NAME + " - Scheduling Administration"}, components : {BasePanel, BaseSettingComponent, BaseSchedulingEntry, UserSelector}})
export default class AdminSchedulingView extends Vue {

  idx = 0
  defaultAppointmentLead : string | null = null

  mounted() {
    this.fetchAppointmentLead()
  }

  fetchAppointmentLead() {
    SettingsResource.getSettingResource(SettingsResource.SETTING_APPOINTMENT_LEAD).get().then((s) => {
      this.defaultAppointmentLead = s.data.value
    }).catch(() => {
      this.defaultAppointmentLead = null
    })
  }
  
  updateLead(leadUri : any) {
    // TODO errors
    SettingsResource.getSettingResource(SettingsResource.SETTING_APPOINTMENT_LEAD).mergePatch({value: leadUri}).finally(() => this.fetchAppointmentLead());
  }

  resourceFor(statusValue : string) {
    return SettingsResource.getSettingResource("scheduling.appointment." + statusValue + ".color")
  }

  get eventColors() : any {
    return SettingsResource.calendarEventColors
  }

  eventColorIdx(colorId : string) {
    if (!colorId) return undefined
    const idx = this.eventColors.findIndex((ec : any) => ec.id == colorId)
    return idx < 0 ? undefined : idx+1
  }

  get domain() {
    return SettingsResource.domain
  }

  get appointmentStatuses() {
    return FormsMetadataResource.Instance.getHalOptions("appointment", "status")
  }

  getStatusColorIdx(statusValue : string) {
    const c = this.getStatusColor(statusValue)
    return c ? c.id : undefined
  }

  getStatusColor(statusValue : string) {
    return AppointmentResource.getStatusColor(statusValue)
  }

  getBackgroundColor(statusValue : string) {
    const c = this.getStatusColor(statusValue)
    return c ? c.background : undefined
  }

  getForegroundColor(statusValue : string) {
    const c = this.getStatusColor(statusValue)
    return c ? c.foreground : undefined
  }


  updateColor(statusValue: string, id : string) {
    this.resourceFor(statusValue).mergePatch({value: id}).then(() => {this.idx++}).catch((e) => console.error(e))
  }

}
